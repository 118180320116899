<template>
  <div>
    <Assign ref="AssignRef" />
    <el-row :gutter="10"
            class="border_top">
      <el-col :span="14">
        <AssignForm ref="AssignFormRef"
                    @validate="validate" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Assign from '../../components/global_check_group_or_user.vue'
import AssignForm from './assignform.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Assign, AssignForm
  },
  methods: {
    validate (form) {
      this.form = form
      let list = this.$refs.AssignRef.checkStudentArr
      if (list.length == 0) {
        this.$message.warning('请选择指定的学生或者分组')
        return
      }
      let user_ids = []
      let organize_ids = []
      list.forEach(item => {
        if (item.organize_id) {
          organize_ids.push(item.organize_id)
        } else {
          user_ids.push(item.id)
        }
      });
      if (organize_ids.length) {
        organize_ids = [...new Set(organize_ids)]
      }
      form.organize_ids = organize_ids
      form.user_ids = user_ids
      // form.isBrackets = true
      this.$http({
        url: '/api/v1/combination/rule_create',
        method: 'post',
        data: form
      }).then(res => {
        this.$router.push('/testPapercomposition/templatePaper/addDetails?edu_paper_id=' + res.data.edu_paper_id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border_top {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}
</style>